<script setup lang="ts">

import { computed, nextTick, ref, watch, onMounted, onUnmounted, Ref } from 'vue'
import TheIcon from '@/Components/TheIcon.vue'
import TheHeaderSearchResult from '@/Components/the-header-search-result/TheHeaderSearchResult.vue'
import { Link, usePage } from '@inertiajs/vue3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { RoomType } from '@/Pages/the-home/enums/Search'
import dayjs from 'dayjs'
import { HeaderSearchResultEmitType } from '@/Components/the-header-search-result/enums/HeaderSearchResultEmitType'
import DateRangePicker from '@/Components/DateRangePicker.vue'
import RoomTypeSwitcher from '@/Components/RoomTypeSwitcher.vue'
import SearchCounter from '@/Components/SearchCounter.vue'
import { vOnClickOutside } from '@vueuse/components'
import { useLocationSearch } from '@/Composables/locationSearch'
import {
    searchOptions,
    fullReload,
    partialReload,
    updateSearchOptions,
    updateCurrentPage,
    updateSortBy,
    orderByDefault
} from '@/Composables/use-listing-search'
import AutocompleteSuggestions from '@/Components/AutocompleteSuggestions.vue'
import GroupTypesSelect from '@/Components/GroupTypesSelect.vue'
import AgeRangesSelect from '@/Components/AgeRangesSelect.vue'
import { useMobile } from '@/Composables/mobile'
import TheLoginDropdown from './login-dropdown/TheLoginDropdown.vue'
import { selectedRoomType } from '@/Composables/searchPlaceByCriteria'
import { headerDirectionMove, headerRef } from '@/Composables/header'
import {
    currencyHeader,
    roomsHeader,
    guestsHeader,
    dateRangeHeader,
    groupAgeRangesHeader,
    groupTypeHeader,
    guestsQuantityHeader,
    inputDateRangeHeader,
    roomsQuantityHeader,
    roomTypeHeader,
    searchInputRefHeader,
    searchPayloadHeader,
    skipDateHeader,
    isGroupTypesVisibleHeader,
    ageRangesValidation,
    isValidAgeRangesSelect,
    toggleIsValidAgeRangesSelect
} from '@/Composables/TheHeaderLogic'
import { ListingTabs } from '@/Pages/the-listing/enums/listingTabs'
import { useFocus, useWindowSize } from '@vueuse/core'
import { toggleIsUserLogged, isUserLogged } from '@/Composables/authorization'
import ComparisonHeaderIcon from '@/Components/the-comparison/ComparisonHeaderIcon.vue'
import { useCurrencyWatcher } from '@/Composables/use-currency'
import { Location } from '@/Pages/the-home/interfaces/Search'
import { hideHeaderWhenScrollToTab, listingsTabs, listingsTabsContent } from '@/Composables/listingTabsLogic'
import { resetAllFilters } from '@/Composables/use-listing-filter'
import { useEscClose } from '@/Composables/use-esc-close'
import { showCriteriaSearchModal } from '@/Composables/use-scroll-&-open-search'

const page = usePage()

type Props = {
    withSearch?: boolean
    returnAfterLogin?: boolean
    location?: Location,
    autoOpenCalendar?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    withSearch: false,
    returnAfterLogin: false,
    location: () => ({
        search: '',
        query: '',
        url: ''
    }),
    autoOpenCalendar: false
})

const { width } = useWindowSize()

const screenSizes = computed(() => {
    return {
        isBigDesktop: width.value >= 1400,
        isDesktop: width.value < 1400 && width.value >= 1200,
        isLaptop: width.value < 1200 && width.value >= 768,
        isTab: width.value < 768 && width.value >= 576,
        isMob: width.value < 576
    }
})

const emit = defineEmits(['tabsSwitchAndScroll'])

const preventHeaderHide = ref(false)

onMounted(() => {
    if (page.props.user) {
        toggleIsUserLogged(true)
    } else {
        toggleIsUserLogged(false)
    }

    headerHeight.value = headerRef.value?.getBoundingClientRect().height || 0

    window.addEventListener('scroll', handleScroll)

    updateSearchOptions(page.props.searchOptions)

    const calendarWasOpen = sessionStorage.getItem('calendarWasOpen')

    if (props.autoOpenCalendar && !isMobile.value && page.props.searchOptions.skipDate) {

        if (!calendarWasOpen) {
            expand(HeaderSearchResultEmitType.DATES)
            sessionStorage.setItem('calendarWasOpen', 'true')
        }
    }
    window.addEventListener('keydown', useEscClose(decreaseHeaderDropdowns))

    toggleIsValidAgeRangesSelect(true)
})

watch(() => isUserLogged.value, async () => {
    await nextTick()

    headerHeight.value = headerRef.value?.getBoundingClientRect().height || 0
})

const headerHeight = ref(0)
const lastScrollTop = ref(0)
let currentScrollTop = 0

const isLocationVisible = ref(false)
const isDatepickerVisible = ref(false)
const isRoomTypeVisible = ref(false)
const isGuestsCounterVisible = ref(false)
const isRoomsCounterVisible = ref(false)
const isRoomsQuantityVisible = computed(() => roomTypeHeader.value === RoomType.PRIVATE)

const {
    isOpened,
    search,
    query,
    url,
    autosuggestionResults,
    autosuggestionResultsVisible,
    isSearchInputValid,
    handleSearchInputClick,
    selectPlace,
    setInitialData,
    isLoadingSuggestions,
    getSuggestions,
    selectAutosuggestionResultViaTheKeyboard,
    activeSuggestionIndex
} = useLocationSearch()

setInitialData(props.location)

const truncatedLocation = computed(() => {
    let maxLength = 45

    if (screenSizes.value.isBigDesktop) {

        maxLength = 50

    } else if (screenSizes.value.isDesktop) {

        maxLength = 20

    } else if (screenSizes.value.isLaptop) {
        maxLength = 10

    } else if (screenSizes.value.isTab) {

        maxLength = 15

    } else if (screenSizes.value.isMob) {
        maxLength = 10
    }

    const noLocationText = 'Where are you going?'

    if (search.value) {
        return search.value.length > maxLength
            ? search.value.slice(0, maxLength) + '...'
            : search.value
    } else {
        return noLocationText.length > maxLength
            ? noLocationText.slice(0, maxLength) + '...'
            : noLocationText
    }
})

const { isMobile } = useMobile()

const mappingEmitTypeToReference = {
    [HeaderSearchResultEmitType.LOCATION]: isLocationVisible,
    [HeaderSearchResultEmitType.DATES]: isDatepickerVisible,
    [HeaderSearchResultEmitType.ROOM_TYPE]: isRoomTypeVisible,
    [HeaderSearchResultEmitType.GUESTS]: isGuestsCounterVisible,
    [HeaderSearchResultEmitType.ROOMS]: isRoomsCounterVisible
}

useFocus(searchInputRefHeader, { initialValue: true })

async function expand (value: HeaderSearchResultEmitType): Promise<void> {
    preventHeaderHide.value = true

    collapseAllOptions()

    if (!document.body.classList.contains('modal-open')) {
        document.body.classList.add('modal-open')
    }

    if (mappingEmitTypeToReference[value]) {
        mappingEmitTypeToReference[value].value = true
    }

    if (value === HeaderSearchResultEmitType.LOCATION && !isOpened.value) {
        isOpened.value = true
        autosuggestionResultsVisible.value = true
        await getSuggestions(search.value)

        return
    }

    if (value === HeaderSearchResultEmitType.LOCATION && isOpened.value) {
        autosuggestionResultsVisible.value = true

        await getSuggestions(search.value)
    }

    isOpened.value = true
}

function collapseAllOptions (): void {
    if (document.body.classList.contains('modal-open')) {
        document.body.classList.remove('modal-open')

        if (isMobile.value) {
            preventHeaderHide.value = false
        }
    }

    for (const key in mappingEmitTypeToReference) {
        mappingEmitTypeToReference[key as keyof typeof mappingEmitTypeToReference].value = false
    }
}

function doSearch (): void {
    skipDateHeader.value = false

    if (isMobile.value && ageRangesValidation()) {
        collapseAllOptions()
    }

    submit()
}

function onSkipDate (): void {
    isDatepickerVisible.value = false
    skipDateHeader.value = true

    resetAllFilters()

    submit()
}

function submit (): void {
    if (!ageRangesValidation()) {
        collapseAllOptions()
        expand(HeaderSearchResultEmitType.GUESTS)

        return
    }

    preventHeaderHide.value = false
    isOpened.value = false

    if (document.body.classList.contains('modal-open')) {
        document.body.classList.remove('modal-open')
    }

    if (!search.value) {
        isSearchInputValid.value = false
        collapseAllOptions()
        isLocationVisible.value = true
        autosuggestionResultsVisible.value = true

        return
    }

    updateSearchOptions(searchPayloadHeader.value)

    updateCurrentPage(1)

    if (searchPayloadHeader.value.skipDate) {
        updateSortBy(orderByDefault)
    }

    if (listingsTabsContent.value instanceof HTMLElement && listingsTabs.value instanceof HTMLElement && headerRef.value) {
        emit('tabsSwitchAndScroll', ListingTabs.ComparePrices)
    }

    if (isSearchOnSamePage()) {
        partialReload()

    } else {
        resetAllFilters()
        updateSortBy(orderByDefault)

        fullReload(url.value)
    }

    function isSearchOnSamePage (): boolean {
        return !url.value || url.value === page.props.currentUrl
    }
}

function handleRoomTypeClick (newType: RoomType): void {
    roomTypeHeader.value = newType

    if (isMobile.value) {
        return
    }

    expand(HeaderSearchResultEmitType.GUESTS)
}

function handleClearInput (): void {
    search.value = ''
    query.value = ''
}

async function handleMobileSearchBtnClick (): Promise<void> {
    await expand(HeaderSearchResultEmitType.LOCATION)
}

useCurrencyWatcher(() => {
    if (!search.value) {
        return
    }

    submit()
})

watch(() => selectedRoomType.value, () => {
    if (selectedRoomType.value) {
        roomTypeHeader.value = selectedRoomType.value as RoomType
    }

    doSearch()
})

// todo: clear
watch(() => searchOptions.value, () => {
    roomTypeHeader.value = searchOptions.value.roomType

    guestsQuantityHeader.value = searchOptions.value.people

    roomsQuantityHeader.value = searchOptions.value.rooms

    groupTypeHeader.value = searchOptions.value.groupType

    groupAgeRangesHeader.value = searchOptions.value.groupAgeRanges

    currencyHeader.value = searchOptions.value.currency

    dateRangeHeader.value = [
        dayjs(searchOptions.value.startDate).format('YYYY-MM-DD'),
        dayjs(searchOptions.value.endDate).format('YYYY-MM-DD')
    ]

}, { deep: true })

const handleScroll = (): void => {
    currentScrollTop = window.scrollY || document.documentElement.scrollTop

    if (preventHeaderHide.value) {
        return
    }

    if (currentScrollTop <= lastScrollTop.value) {
        headerDirectionMove.value = 'top'

    } else {
        headerDirectionMove.value = 'down'
    }

    lastScrollTop.value = currentScrollTop <= 0 ? 0 : currentScrollTop
}

const decreaseHeaderDropdowns = (): void => {
    if (document.body.classList.contains('modal-open')) {
        document.body.classList.remove('modal-open')
    }

    isOpened.value = false
    preventHeaderHide.value = false
}

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
    window.removeEventListener('keydown', useEscClose(decreaseHeaderDropdowns))
})

const handleSearchInputAtMobile = (event: Event): void => {
    const target = event.target as HTMLInputElement

    search.value = target.value.trim()
}

const locationDropdownRef: Ref<HTMLElement | null> = ref(null)

const locationDropdownHeight = computed(() => {
    if (locationDropdownRef.value && !isMobile.value && window) {
        const windowHeight = window.innerHeight

        return windowHeight - locationDropdownRef.value.getBoundingClientRect().top - 16
    }
})

const headerHideAnimation = computed(() => {
    if (hideHeaderWhenScrollToTab.value) {
        return {
            top: `-${ headerHeight.value }px`
        }
    }

    if (preventHeaderHide.value) {
        return {
            top: '0px'
        }
    }

    return {
        top: headerDirectionMove.value === 'top' ? '0px' : `-${ headerHeight.value }px`
    }
})

watch(showCriteriaSearchModal, () => {
    expand(showCriteriaSearchModal.value)
}, { deep: true })

const handleKeyDown = (event: KeyboardEvent): void => {
    selectAutosuggestionResultViaTheKeyboard(event, () => {
        isDatepickerVisible.value = true
        isLocationVisible.value = false
    })
}

</script>

<template>
    <header
        ref="headerRef"
        class="header bg-white"
        :style="headerHideAnimation"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav class="header-navbar navbar navbar-light navbar-expand-lg">
                        <Link
                            :href="route('home')"
                            title="Hostelz.com - the worlds largest hostel database"
                            class="navbar-brand"
                        >
                            <span class="d-none d-lg-inline">
                                <TheIcon icon-id="hostelz-logo" width="104" height="24" />
                                    
                                <div class="text-sm">Price Comparison</div>
                            </span>

                            <span class="d-lg-none">
                                <TheIcon
                                    class="mb-2"
                                    icon-id="hostelz-logo-mobile"
                                    width="69"
                                    height="16"
                                />
                                    
                                <div class="text-xxs">Price Comparison</div>
                            </span>
                        </Link>

                        <TheHeaderSearchResult
                            v-if="withSearch"
                            v-model="isOpened"
                            :location="truncatedLocation"
                            :dates="searchOptions.skipDate ? 'Choose date' : inputDateRangeHeader"
                            :room-type="roomTypeHeader"
                            :guests="guestsHeader"
                            :rooms="roomsHeader"
                            :is-expanded="isOpened && !isMobile"
                            @expand="expand"
                        />

                        <div class="d-none d-lg-block">
                            <!-- Navbar Collapse -->
                            <div id="navbarCollapse" class="collapse navbar-collapse">
                                <div
                                    v-if="withSearch"
                                    class="form-inline mt-4 mb-sm-2 d-sm-none"
                                >
                                    <div
                                        class="input-label-absolute input-label-absolute-left input-reset input-expand ml-lg-2 ml-xl-3"
                                    >
                                        <label for="search_search" class="label-absolute">
                                            <FontAwesomeIcon :icon="faSearch" />

                                            <span class="sr-only">Where are you traveling?</span>
                                        </label>

                                        <button type="reset" class="btn btn-reset btn-sm">
                                            <FontAwesomeIcon :icon="faTimes" />
                                        </button>
                                    </div>
                                </div>

                                <div class="header-auth">
                                    <ul
                                        class="navbar-nav ml-auto flex-sm-row justify-content-sm-between align-items-center"
                                    >
                                        <ComparisonHeaderIcon />

                                        <TheLoginDropdown />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <template v-if="isMobile">
                            <button
                                v-if="!withSearch"
                                class="search-btn btn-clear cursor-pointer"
                                @click="handleMobileSearchBtnClick"
                            >
                                <TheIcon icon-id="search-icon-2" width="24" height="24" />
                                Where are you going?
                            </button>

                            <button
                                v-else
                                class="btn-clear cursor-pointer d-md-none d-block"
                                @click="handleMobileSearchBtnClick"
                            >
                                <TheIcon icon-id="search-icon-2" width="24" height="24" />
                            </button>

                            <button
                                v-if="withSearch"
                                class="btn btn-danger btn-primary d-md-flex d-none justify-content-center header-save-btn"
                                @click="expand(HeaderSearchResultEmitType.LOCATION)"
                            >
                                <span>Let's save!</span>
                            </button>

                            <!--mobile location-->
                            <div
                                v-if="isLocationVisible"
                                id="modalMobileSearchLocation"
                                class="modal right fade modal-mobile-search-location d-block show"
                            >
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="d-flex justify-content-between">
                                                <button
                                                    class="btn-clear mobile-search-prev"
                                                    style="stroke: #FF5852; margin-right: 14px;"
                                                    data-dismiss="modal"
                                                >
                                                    <TheIcon
                                                        icon-id="arrow-prev"
                                                        width="24"
                                                        height="24"
                                                        @click="collapseAllOptions"
                                                    />
                                                </button>

                                                <div
                                                    class="position-relative searchLocationMobileWrap flex-grow-1"
                                                >
                                                    <span
                                                        class="position-absolute"
                                                        style="left: 20px; top:12px; fill: #4A5268;"
                                                    >
                                                        <TheIcon icon-id="search-icon" width="22" height="22" />
                                                    </span>

                                                    <input
                                                        ref="searchInputRefHeader"
                                                        v-on-click-outside="() => autosuggestionResultsVisible = false"
                                                        placeholder="Where are you traveling?"
                                                        type="text"
                                                        autocomplete="off"
                                                        autocorrect="off"
                                                        autocapitalize="off"
                                                        autofocus
                                                        class="searchLocationMobile form-control bg-light rounded-xl border-0 cl-subtext"
                                                        style="padding-left: 54px; padding-right: 54px;"
                                                        :value="search"
                                                        @input="handleSearchInputAtMobile"
                                                        @keydown="handleKeyDown"
                                                        @click="handleSearchInputClick"
                                                    >

                                                    <span
                                                        class="position-absolute cursor-pointer search-autocomplete-clear"
                                                        style="right: 20px; top:12px; fill: #4A5268;"
                                                        @click="handleClearInput"
                                                    >
                                                        <TheIcon icon-id="close-icon" width="22" height="22" />
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="location-suggestion">
                                                <div
                                                    v-if="isLoadingSuggestions"
                                                    class="d-flex justify-content-center spinner-wrap mt-3"
                                                >
                                                    <div class="spinner-border text-primary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <AutocompleteSuggestions
                                                v-show="!isLoadingSuggestions && autosuggestionResultsVisible"
                                                :suggestions="autosuggestionResults"
                                                :active-index="activeSuggestionIndex"
                                                @select-place="selectPlace($event); isLocationVisible = false; isDatepickerVisible = true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--mobile dates-->
                            <div
                                v-if="isDatepickerVisible"
                                id="modalMobileSearchDates"
                                class="modal right fade d-block show"
                            >
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-body d-flex flex-column">
                                            <div class="d-flex justify-content-between">
                                                <button
                                                    type="button"
                                                    class="btn-clear"
                                                    style="stroke: #FAFBFE"
                                                    @click="handleMobileSearchBtnClick"
                                                >
                                                    <TheIcon icon-id="arrow-prev" width="24" height="24" />
                                                </button>

                                                <div class="align-items-center d-flex flex-grow-1">
                                                    <div class="form-title">
                                                        {{ inputDateRangeHeader }}
                                                    </div>
                                                </div>

                                                <button
                                                    type="button"
                                                    class="btn-clear"
                                                    @click="collapseAllOptions"
                                                >
                                                    <TheIcon icon-id="close-icon-3" width="24" height="24" />
                                                </button>
                                            </div>

                                            <DateRangePicker
                                                v-model="dateRangeHeader"
                                                dark
                                                :multi-calendars="false"
                                                class="justify-content-center p-5"
                                                @dates-selected="expand(HeaderSearchResultEmitType.ROOM_TYPE)"
                                            />

                                            <div class="d-flex mt-auto justify-content-between">
                                                <button
                                                    type="button"
                                                    class="btn btn-outline-light"
                                                    @click="onSkipDate"
                                                >
                                                    skip dates
                                                </button>

                                                <button
                                                    type="button"
                                                    class="btn btn-lg btn-primary d-flex px-5 justify-content-center"
                                                    @click="collapseAllOptions(); doSearch()"
                                                >
                                                    <TheIcon icon-id="search-icon-3" width="24" height="24" />

                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--mobile guests-->
                            <div
                                v-if="isRoomTypeVisible || isGuestsCounterVisible"
                                id="modalMobileSearchGuests"
                                class="modal right fade d-block show"
                            >
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-body d-flex flex-column">
                                            <div class="d-flex justify-content-between mb-3">
                                                <button
                                                    class="btn-clear"
                                                    style="stroke: #FF5852"
                                                    @click="expand(HeaderSearchResultEmitType.DATES)"
                                                >
                                                    <TheIcon icon-id="arrow-prev" width="24" height="24" />
                                                </button>

                                                <div class="align-items-center d-flex flex-grow-1">
                                                    <div class="font-weight-600 cl-text">
                                                        Room details
                                                    </div>
                                                </div>

                                                <button
                                                    class="btn-clear"
                                                    @click="collapseAllOptions"
                                                >
                                                    <TheIcon icon-id="close-icon-3" width="24" height="24" />
                                                </button>
                                            </div>

                                            <div class="mb-3">
                                                <RoomTypeSwitcher
                                                    v-model="roomTypeHeader"
                                                    @room-type-click="handleRoomTypeClick"
                                                />

                                                <SearchCounter
                                                    v-if="isRoomsQuantityVisible"
                                                    v-model="roomsQuantityHeader"
                                                    label="Rooms"
                                                />
                                            </div>

                                            <div class="mb-3">
                                                <SearchCounter v-model="guestsQuantityHeader" label="Guests" />

                                                <template v-if="isGroupTypesVisibleHeader">
                                                    <GroupTypesSelect v-model="groupTypeHeader" />

                                                    <AgeRangesSelect
                                                        v-model="groupAgeRangesHeader"
                                                        :is-valid="isValidAgeRangesSelect"
                                                    />
                                                </template>
                                            </div>

                                            <div class="d-flex mt-auto">
                                                <button
                                                    class="searchMobileButton btn btn-lg btn-danger btn-primary d-flex px-5 w-100 justify-content-center"
                                                    @click="doSearch"
                                                >
                                                    <span class="ml-2">Let's save!</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </nav>
                </div>

                <div
                    v-if="withSearch && isOpened && !isMobile"
                    id="header-search-form__wrap"
                    class="col d-none d-lg-block"
                >
                    <div class="d-flex justify-content-center">
                        <div id="header-search-form" class="mb-lg-5 d-inline-block">
                            <div class="d-flex flex-row align-items-center">
                                <div class="delimiter-line search-dropdown-wrap">
                                    <button
                                        class="btn-clear dropdown-toggle-btn"
                                        :class="{ 'dropdown-toggle-btn--active': isLocationVisible }"
                                        @click="expand(HeaderSearchResultEmitType.LOCATION)"
                                    >
                                        {{ query || 'Location' }}
                                    </button>

                                    <div
                                        v-if="isLocationVisible"
                                        ref="locationDropdownRef"
                                        class="dropdown-menu location-suggestion p-3 p-lg-5 show overflow-auto"
                                        :style="{maxHeight: locationDropdownHeight + 'px'}"
                                    >
                                        <div class="form-inline mb-3" style="padding-left: 1px;">
                                            <span class="form-group position-relative">
                                                <span class="position-absolute" style="left: 20px; fill: #4A5268;">
                                                    <TheIcon icon-id="search-icon" width="22" height="22" />
                                                </span>

                                                <input
                                                    ref="searchInputRefHeader"
                                                    v-model.trim="search"
                                                    v-on-click-outside="() => autosuggestionResultsVisible = false"
                                                    placeholder="Where are you traveling?"
                                                    type="text"
                                                    class="searchLocation form-control bg-light rounded-xl border-0 cl-subtext"
                                                    style="padding-left: 54px; padding-right: 54px;"
                                                    @click="handleSearchInputClick"
                                                    @keydown="handleKeyDown"
                                                >

                                                <span
                                                    class="position-absolute cursor-pointer search-autocomplete-clear"
                                                    style="right: 20px; fill: #4A5268;"
                                                    @click="handleClearInput"
                                                >
                                                    <TheIcon icon-id="close-icon" width="22" height="22" />
                                                </span>
                                            </span>
                                        </div>

                                        <div
                                            v-if="isLoadingSuggestions"
                                            class="d-flex justify-content-center spinner-wrap mt-3"
                                        >
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>

                                        <AutocompleteSuggestions
                                            v-show="!isLoadingSuggestions && autosuggestionResultsVisible"
                                            :suggestions="autosuggestionResults"
                                            :active-index="activeSuggestionIndex"
                                            :autocomplete-suggestions-wrap="locationDropdownRef"
                                            @select-place="selectPlace($event); isLocationVisible = false; isDatepickerVisible = true"
                                        />
                                    </div>
                                </div>

                                <div class="delimiter-line search-dropdown-wrap">
                                    <button
                                        class="btn-clear date-title dropdown-toggle-btn"
                                        :class="{ 'dropdown-toggle-btn--active': isDatepickerVisible }"
                                        @click="expand(HeaderSearchResultEmitType.DATES)"
                                    >
                                        {{ inputDateRangeHeader || 'Add dates' }}
                                    </button>

                                    <div
                                        v-if="isDatepickerVisible"
                                        class="dropdown-menu datepicker-search-top show p-4"
                                    >
                                        <DateRangePicker
                                            v-model="dateRangeHeader"
                                            @dates-selected="expand(HeaderSearchResultEmitType.ROOM_TYPE)"
                                        />

                                        <button
                                            type="button"
                                            class="btn btn-sm btn-primary mr-auto mt-2"
                                            @click="onSkipDate"
                                        >
                                            skip dates
                                        </button>
                                    </div>
                                </div>

                                <div class="delimiter-line search-dropdown-wrap bookingSearchRoomType">
                                    <button
                                        class="btn-clear dropdown-toggle-btn"
                                        :class="{ 'dropdown-toggle-btn--active': isRoomTypeVisible }"
                                        @click="expand(HeaderSearchResultEmitType.ROOM_TYPE)"
                                    >
                                        {{ roomTypeHeader }}
                                    </button>

                                    <div
                                        v-if="isRoomTypeVisible"
                                        class="dropdown-menu p-3 p-lg-5 show"
                                    >
                                        <RoomTypeSwitcher
                                            v-model="roomTypeHeader"
                                            @room-type-click="handleRoomTypeClick"
                                        />
                                    </div>
                                </div>

                                <div class="delimiter-line search-dropdown-wrap">
                                    <button
                                        class="btn-clear dropdown-toggle-btn"
                                        :class="{ 'dropdown-toggle-btn--active': isGuestsCounterVisible }"
                                        @click="expand(HeaderSearchResultEmitType.GUESTS)"
                                    >
                                        {{ guestsHeader }}
                                    </button>

                                    <div
                                        v-if="isGuestsCounterVisible"
                                        class="dropdown-menu p-3 p-lg-5 show"
                                    >
                                        <div>
                                            <div
                                                class="d-flex flex-column justify-content-center align-items-start bk-search__wrap"
                                            >
                                                <SearchCounter v-model="guestsQuantityHeader" label="Guests" />
                                            </div>

                                            <template v-if="isGroupTypesVisibleHeader">
                                                <GroupTypesSelect v-model="groupTypeHeader" />

                                                <AgeRangesSelect
                                                    v-model="groupAgeRangesHeader"
                                                    :is-valid="isValidAgeRangesSelect"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="isRoomsQuantityVisible" class="position-relative">
                                    <button
                                        class="btn-clear dropdown-toggle-btn"
                                        :class="{ 'dropdown-toggle-btn--active': isRoomsCounterVisible }"
                                        @click="expand(HeaderSearchResultEmitType.ROOMS)"
                                    >
                                        {{ roomsHeader }}
                                    </button>

                                    <div
                                        v-if="isRoomsCounterVisible"
                                        class="dropdown-menu p-3 p-lg-5 show"
                                    >
                                        <div class="searchRoomsOptions">
                                            <div
                                                class="d-flex flex-column justify-content-center align-items-start bk-search__wrap"
                                            >
                                                <SearchCounter v-model="roomsQuantityHeader" label="Rooms" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        class="btn btn-danger btn-primary d-flex justify-content-center header-save-btn"
                                        @click="doSearch"
                                    >
                                        <span>Let's save!</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="header-line" />

    <div v-if="isOpened && !isMobile" class="header-search-overlay" @click="decreaseHeaderDropdowns" />
</template>

<style lang="scss" scoped>
.header-search-overlay {
    background-color: rgba(10, 27, 65, .6);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1052;
}

.dropdown-toggle-btn--active {
    background: #4A5268;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.08), inset 0px -1px 0px rgba(14, 14, 44, 0.24);
    color: #FAFBFE !important;
    border-radius: 8px;
}

.search-btn {
    font-size: 14px;
    padding: 10px 16px;
    background: #fafbfe;
    border-radius: 23px;
    color: #787d8d;
    line-height: 140%;
    flex-grow: 1;
}

.header {
    width: 100%;
    top: 0;
    transition: top 1s cubic-bezier(0.2, 1, 0.3, 1);
    position: sticky;

    &-line {
        width: 100%;
        height: 1px;
        background-color: var(--accent-bg);

        @media (min-width: 992px) {
            width: 100%;
            height: 5px;
        }
    }

    &-save-btn {
        @media (min-width: 576px) {
            font-size: 14px;
            margin-left: auto;
            padding: 4px 8px;
        }

        @media (min-width: 768px) {
            padding: 8px 16px;
            margin-left: 0;
        }
    }
}

#header-search-form .dropdown-toggle-btn {

    @media (min-width: 992px) {
        width: 150px;
        margin: 0px 4px;
    }

    @media (min-width: 1200px) {
        width: 160px;
        margin: 0px 8px;
    }
}

.modal {
    height: 91vh;

    @supports (height: 100dvh) {
        height: 100dvh;
    }
}

.modal.right .modal-dialog {
    height: 91vh;

    @supports (height: 100dvh) {
        height: 100dvh;
    }
}
</style>